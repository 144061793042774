.image-gallery-wrapper {
    flex: 1;
    height: 100%;
}

.image-gallery-wrapper>div {
    display: flex;
    flex: 1;
    height: 100%;
    align-items: stretch;
    flex-direction: column;
}

.image-gallery {
    flex-direction: column;
    flex: 1;
    height: 100%;
}

.image-gallery-content {
    flex: 1;

    height: 100%;
    display: flex;
    flex-direction: column;
}

.image-gallery-bullets {
    flex: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
}

.image-gallery-slide-wrapper {
    flex: 1;
    height: 100%;
    justify-content: space-around;

    display: flex;
    flex-direction: column;
}

.image-gallery-swipe {
    display: flex;
    flex: 1;
    height: 100%;

    justify-content: space-around;
    align-items: center;
}

.image-gallery-thumbnails-wrapper {
    flex: 0;

    display: flex;
    height: 100%;
    align-items: center;

    justify-content: center;
}

.file-upload:hover {
    border-color: #777;
}

.file-upload input {
    display: none;
}

.file-upload-label {
    font-size: 16px;
    color: #333;
    margin-bottom: 10px;
}

.file-upload-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.file-upload-button:hover {
    background-color: #326633;
}

.border-box {
    border: 1px solid #e0e0e0;
    padding: 10px;
    margin: 10px;
}

dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: none;
    border-radius: 4px;
    padding: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 50vw;
    height: 50vh;
}

dialog>div {
    height: 100%;
    display: flex;
    flex-direction: column;
}

dialog>div .confirm-buttons {
    display: flex;
    gap: 3rem;
    flex: 0 0 4rem;
}

dialog h2 {
    flex: 0;
}

dialog ul {
    flex: 1;
    margin: 0 2rem;
    font-size: 1.5rem;
}

dialog button {
    flex: 1;
}

dialog::backdrop {
    background: rgba(0, 0, 0, 0.5);
}

.pricing-table {
    width: 100%;
    border-collapse: collapse;
}

.pricing-table th,
.pricing-table td {
    border: 1px solid #ddd;
    padding: 8px;
}

.pricing-table th {
    background-color: #326633;
    color: white;
    text-align: left;
}

.pricing-table td {
    text-align: left;
}

.pricing-table tr:nth-child(even) {
    background-color: #f2f2f2;
}

.pricing-table tr:hover {
    background-color: #ddd;
}

.confirm-buttons {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.confirm-buttons button {
    margin: 0 10px;
}

.status-message {
    font-size: 16px;
    margin: 8px 0;
}

.button-container {
    display: flex;
    justify-content: space-between;
    padding: 16px;
}

.button {
    padding: 8px 16px;
    font-size: 14px;
}

.button p {
    margin: 0;
}
