.indexedStep {
    color: rgb(190, 190, 190);
    width: 65px;
    height: 65px;
    font-size: 11px;
    background-color: rgb(255, 255, 255);
    border-radius: 100%;
    border-style: solid;
    border-width: 4px;
    border-color: #F2AC00;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.indexedStep.accomplished {
    background-color: #326633;
    border-color: #F2AC00;
    color: white;
    border-style: none;
}


.indexedStep.current-step  {
    border: 4px solid #F2AC00; 
}

.indexedStep.accomplished:hover {
    border: 4px solid #F2AC00; 
}

.indexedStep.current-step.accomplished:hover {
    border: 8px solid #F2AC00; 
}

.RSPBprogressBar {
    height: 3px;
    width: 30%;
    line-height: 1;
    border-radius: 10px;
    position: relative;
    background-color: #F2AC00;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 0;
    margin: 20px auto;
}

.RSPBprogressBar .RSPBstep {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    z-index: 0;
    position: absolute;
    transform: translateX(-50%);
    transition-property: all;
    transition-timing-function: ease;
}

.RSPBprogressBar .RSPBprogressBarText {
    color: white;
    font-size: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.RSPBprogressBar .RSPBprogression {
    position: absolute;
    transition: width 0.3s ease;
    left: 0;
    top: 0;
    bottom: 0;
    border-radius: 10px;
    background: #F2AC00;
    z-index: -1;
}

@media screen and (max-width: 480px) {
    .indexedStep{
        width: 15px;
        height: 15px;
        font-size: 6px;
    }
}
