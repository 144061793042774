/* Google fonts import link */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}

body {
    height: 100vh;
    /*display: flex;*/
    align-items: center;
    justify-content: center;
    /*background: #7D2Ae8;*/
}

.wrapper {
    user-select: none;
}

.wrapper .time {
    height: 100px;
    /*background: #fff;*/
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    /*box-shadow: 10px 10px 20px rgba(0,0,0,0.09);*/
    padding: 0 10px;
}

.wrapper .time span {
    width: 100px;
    text-align: center;
    font-size: 50px;
    font-weight: 700;
    /*color: #333;*/
}

.time span.colon {
    width: 10px;
}

.time span.ms-colon,
.time span.millisecond {
    color: #ffdd04;
}

.wrapper .buttons {
    text-align: center;
    margin-top: 20px;
}

.buttons button {
    padding: 6px 16px;
    outline: none;
    border: none;
    margin: 0 5px;
    color: #7D2Ae8;
    background: #fff;
    font-size: 19px;
    font-weight: 500;
    border-radius: 4px;
    cursor: pointer;
    box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.09);
}

.buttons button.active,
.buttons button.stopActive {
    pointer-events: none;
    opacity: 0.7;
}

.highlight {
    color: #F2AC00;
    /* Gold color for highlighting active numbers */
}

/* CSS for styling the form */

.claim-form {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f9f9f9;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.form-label {
    display: block;
    margin-bottom: 15px;
    font-weight: bold;
    font-size: 14px;
}

.form-input,
.form-textarea {
    width: calc(100% - 20px);
    padding: 8px 10px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    box-sizing: border-box;
}

.form-textarea {
    resize: vertical;
    height: 100px;
}

.form-submit {
    display: inline-block;
    padding: 10px 20px;
    font-size: 16px;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.form-submit:hover {
    background-color: #0056b3;
}

/* CSS for styling the Home component */

.mainContainer {
    max-width: 800px;
    margin: 0 auto;
    margin-top: 150px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f9f9f9;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    font-family: Arial, sans-serif;
}

.titleContainer h1 {
    margin: 0;
    padding: 20px 0;
    font-size: 24px;
    color: #333;
}

.contentContainer {
    font-size: 18px;
    color: #666;
    margin: 20px 0;
}

.buttonContainer {
    margin-top: 20px;
}

.inputButton {
    padding: 10px 20px;
    font-size: 16px;
    color: #F2AC00;
    background-color: #F2AC00;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.inputButton:hover {
    opacity: 50%;
}

.emailDisplay {
    margin-top: 10px;
    font-size: 16px;
    color: #333;
}
