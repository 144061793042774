header {
    background-color: #326633;
}

.header-wrapper {
    max-width: 1024px;
    margin: 0 auto;
    display: flex;
    padding: 1rem 0;
    margin-bottom: 4rem;
    color: #ffffff;
}

.back-page {
    position:relative;
    top: 10px;
    left: 20px;
}

.header-wrapper h1 {
    font-size: 2rem;
}

.form-submit {
    font-size: small;
}

.image-gallery-wrapper {
    min-width: 500px;
    min-height: 450px;
    /*width: 100%;*/
    margin: 0 auto;
    border: 1px solid #e0e0e0;
}

.text-wrapper {
    padding: 10px;
    /*width: 100%;*/
    margin: 0 auto;
    border: 1px solid #e0e0e0;
}

button {
    color: #F2AC00;
}

@media only screen and (min-device-width: 375px) {
    .header-wrapper {
        padding: 1rem;
    }
}

@media only screen and (max-device-width: 875px) {

    .image-gallery-wrapper {
        min-width: 10vw;
        min-height: 9vw;
        /*width: 100%;*/
        margin: 0 auto;
        border: 1px solid #e0e0e0;
        zoom: 0.5;
    }

    .main-wrapper{
        zoom: 50%;
    }


    .text-wrapper {
        margin-top: 5rem;
        padding: 10px;
        /*width: 100%;*/
        margin: 0 auto;
        border: 1px solid #e0e0e0;
    }
}
