.pdf-viewer {
    text-align: center;
    font-family: Arial, sans-serif;
    margin-bottom: 10vh;
}

.pdf-document {
    display: inline-block;
    border: 1px solid white;
    margin: 20px;
    padding: 20px;
    background: white;
    border: 1px solid #ddd;
    border-radius: 3px;

}

header {
    background-color: #326633;
}

.header-wrapper-pdf {
    max-width: 1024px;
    margin: 0 auto;
    display: flex;
    padding: 1rem 0;
    margin-bottom: 4rem;
    color: #ffffff;
}

.header-wrapper h1 {
    font-size: 2rem;
}

.controls {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin: 20px 0;
    margin-right: 40px;
}

.page-controls {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin: 20px 0;
}


.download-controls {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin: 20px 0;
}

.control-main {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin: 10px;
}

.back-controls {
    display: flex;

    margin: 15px 0;
}

.pdf-view-btn {
    padding: 10px 10px;
    font-size: 16px;
    cursor: pointer;
    background-color: white;
    border: 1px solid #ddd;
    color: red;
    border-radius: 10px;
}

.pdf-view-btn:hover {
    background-color: #f9f9f9;
}

.pdf-btn {
    padding: 8px 10px;
    align-items: center;
    justify-content: center;
    display: flex;
    font-size: 16px;
    cursor: pointer;
    border: 1px solid #ddd;
    border-radius: 10px;
    background-color: white;

}

.pdf-btn:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    border: 1px solid #ddd;
    border-radius: 10px;

}

.pdf-btn:hover {
    background-color: #f9f9f9;
}

/* Hide text layer */
.react-pdf__Page__textContent {
    display: none !important;
}
