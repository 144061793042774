/* Image Viewer Container */
.image-viewer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  border: 2px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  background-color: #f9f9f9;
  position: relative;
  width: 100%;
  max-width: 600px;
}

.image-wrapper {
  width: 100%;
  max-height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.image-viewer-img {
  max-width: 80%;
  max-height: 80%;
  width: auto;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  object-fit: contain; /* Ensures image fits without stretching */
}

.image-viewer-controls {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.viewer-button {
  background-color: #f2ac00;
  border: none;
  color: white;
  padding: 10px 20px;
  font-size: 18px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.viewer-button:hover {
  background-color: #e9d194;
}

.image-count {
  font-size: 16px;
  color: #333;
}

.fullscreen-button {
  background-color: #f2ac00;
  color: white;
  border: none;
  padding: 10px 15px;
  font-size: 15px;
  border-radius: 5px;
  cursor: pointer;
  margin: auto;
  margin-top: 15px;
}

.fullscreen-button:hover {
  background-color: #e9d194;
}

/* Modal Styles */
.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 100000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
}

.modal-content {
  position: relative;
  padding: 20px;
  border-radius: 10px;
  max-width: 90vw;
  max-height: 90vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-image {
  max-width: 100%;
  max-height: 100%;
  border-radius: 8px;
  object-fit: contain; /* Ensures image fits within modal without distortion */
}

.close-button {
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 24px;
  color: white;
  cursor: pointer;
  background-color: transparent;
  border: none;
}

/* Responsive Styles */

/* For devices with a width less than 768px (tablets, mobile devices) */
@media (max-width: 768px) {
  .image-viewer-container {
    max-width: 90vw;
    padding: 10px;
  }

  .image-viewer-img {
    width: 100%;
    height: auto;
  }

  .fullscreen-button {
    width: 40%;
  }
  .image-wrapper {
    min-height: 300px;
    min-width: 400px;
  }

  .modal-content {
    width: 95vw;
    height: 90vh;
  }

  .modal-image {
    width: 100%;
    height: auto;
  }
}

/* For devices with a width less than 480px (small mobile devices) */
@media (max-width: 480px) {
  .image-viewer-container {
    max-width: 95vw;
  }

  .image-viewer-img {
    width: 100%;
    height: auto;
  }

  .image-wrapper {
    min-height: 200px;
    min-width: 300px;
  }

  .fullscreen-button {
    width: 50%;
  }

  .modal-content {
    width: 95vw;
    height: 95vh;
  }

  .modal-image {
    width: 100%;
    height: auto;
  }

  .viewer-button {
    padding: 8px 15px;
    font-size: 16px;
  }
}

/* For large screens, default desktop styles */
@media (min-width: 1024px) {
  .image-viewer-container {
    max-width: 50vw;
  }

  .image-viewer-img {
    width: 100%;
    height: auto;
  }

  .image-wrapper {
    min-height: 400px;
    min-width: 500px;
  }

  .modal-content {
    width: 70vw; /* Increase modal size */
    height: 70vh;
  }

  .modal-image {
    width: 100%; /* Larger size for fullscreen on big screens */
    height: auto;
  }
}
