body {
    margin: 0;
    font-family: 'Helvetica', 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow: hidden;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

html,
body {
    padding: 0;
    margin: 0;

    /* these lines stop navbar from disappearing */
    position: fixed;
    width: 100%;
}

* {
    box-sizing: border-box;
}


.App>div {
    height: 90vh;
}

body {
    justify-content: center;
    align-items: center;
    height: 100vh;
}

code {
    background: #fafafa;
    border-radius: 5px;
    padding: 0.75rem;
    font-family:
        Menlo,
        Monaco,
        Lucida Console,
        Courier New,
        monospace;
}

input[type='button'],
input[type='submit'] {
    border: none;
    background: #F2AC00;
    color: white;
    padding: 12px 24px;
    margin: 8px;
    font-size: 24px;
    border-radius: 8px;
    cursor: pointer;
}
